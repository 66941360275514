<!-- eslint-disable-line vue/multi-word-component-names -->
<template>
  <div
    class="w-full h-full pt-[47px] flex flex-col justify-center items-center"
  >
    <!-- EngagedSquadView anchor -->
    <div
      v-if="!state.active_caverns_run"
      id="caverns_engaged_heroes_view"
      class="mt-[3%] h-[20vh] w-[800px] py-1 px-3 bg-white/10 rounded-md"
    >
      <div
        :class="{
          'text-xl font-bold underline text-zink-400 no-select': true,
        }"
      >
        {{ text(`ui.game_submodes.${state.current_game_submode}`) }}
        {{ text('ui.caverns_squad') }}:
      </div>
    </div>

    <!-- active caverns controls -->
    <div
      v-if="!state.active_caverns_run"
      class="mt-2 p-2 border border-zinc-700 border-t-0 rounded-md"
    >
      <div
        id="caverns_submode_info_bar"
        class="w-full px-4 text-center"
      >
        <!-- e.g. 3-man Deepest Level: 27 -->
        <div class="text-yellow-300">
          {{ text(`ui.game_submodes.${state.current_game_submode}`) }}
          {{ text('ui.deepest_level') }}: {{ state.deepest_level }}
        </div>
      </div>
      <div
        id="caverns_buttons_panel"
        :class="{
          'mt-2 h-18 w-[440px] p-2 flex justify-center': true,
          'items-center': state.portal_level,
          'items-end': !state.portal_level,
        }"
      >
        <div>
          <DTButton
            id="enter_at_1_button"
            type="info"
            :class="{
              'w-[210px] py-1': true,
              'pointer-events-none': state.is_on_tutorial_step_nav_to_edit_hero,
            }"
            :disabled="
              state.is_on_tutorial_step_nav_to_edit_hero ||
              state.pending_enter_caverns
            "
            @click="onDescendCavernsAt1Click"
          >
            <DTAnimatedElipsis
              v-if="state.pending_enter_caverns"
              class="text-2xl font-extrabold"
            />
            <span
              v-else
              class="text-sm"
            >
              {{ text('ui.descend_caverns_at_1') }}
            </span>
          </DTButton>
          <div
            v-if="state.portal_level"
            class="w-full text-right"
          >
            <DTButton
              type="portal"
              class="mt-2 py-1 w-[210px]"
              :disabled="state.pending_enter_caverns"
              @click="onDescendCavernsAtPortalLevelClick"
            >
              <DTAnimatedElipsis
                v-if="state.pending_enter_caverns"
                class="text-2xl font-extrabold"
              />
              <span
                v-else
                class="px-2 text-sm"
              >
                {{
                  text('ui.descend_caverns_at_portal_level')(state.portal_level)
                }}
              </span>
            </DTButton>
            <span class="mr-3 text-xs text-yellow-300">
              {{
                Tools.formatGameCurrency(
                  Economy.CAVERNS_FEE_PER_LEVEL * state.portal_level
                ) +
                ' ' +
                text('ui.gold_brief')
              }}
            </span>
          </div>
        </div>
        <div class="ml-8 text-center">
          <CavernsPassiveYieldView />
          <DTButton
            type="neutral"
            :class="{
              'w-[150px] py-1 px-5': true,
              'mb-[25px]': state.deepest_level > 1,
            }"
            :disabled="
              state.pending_claim_delay ||
              state.pending_claim_call ||
              state.pending_enter_caverns
            "
            @click="
              () => {
                state.pending_claim_call = true;
                CavernsActions.claimPassiveYields();
              }
            "
          >
            <DTAnimatedElipsis
              v-if="state.pending_claim_call || state.pending_enter_caverns"
              class="text-2xl font-extrabold"
            />
            <span v-else-if="state.pending_claim_delay">
              {{ state.tickers.claim_delay_text }}
            </span>
            <span v-else>{{ text('ui.claim') }}</span>
          </DTButton>
        </div>
      </div>
    </div>

    <!-- active caverns battle view -->
    <div
      v-if="state.active_caverns_run"
      id="caverns_battle_root"
      class="w-full h-full relative flex justfy-center"
    >
      <div
        id="caverns_left_sidebar"
        class="w-[33%] h-full absolute left-[18px]"
      ></div>
      <div
        id="caverns_battle_body_wrapper"
        class="w-full h-full relative flex justify-center"
      >
        <div
          id="caverns_battle_window"
          class="w-[34%] h-[60vh] mt-6"
        ></div>
      </div>
      <CavernsBattleLog
        class="w-[26%] h-[calc(100%-5vh)] absolute top-[4vh] right-[20px]"
      />
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, reactive } from 'vue';
import Economy from 'dt-common/constants/Economy';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import Audio from '~/Audio';
import {
  CavernsActions,
  HeroBuildActions,
  TutorialActions,
} from '~/flux/actions';
import {
  CavernsStore,
  GameStateStore,
  HeroBuildStore,
  TutorialStore,
  UIStore,
} from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import {
  EngagedSquadView,
  GameSubmodeSelector,
  SquadPuppetMouseoverPanel,
} from '~/view/components/common/canvas';
import { DTAnimatedElipsis, DTButton } from '~/view/components/common/DOM';
import {
  TutorialBox,
  TutorialSpotlight,
} from '~/view/components/common/canvas';
import CavernsBattleWindow from '../canvas/CavernsBattleWindow';
import CavernsBattleLog from './CavernsBattleLog.vue';
import CavernsPassiveYieldView from './CavernsPassiveYieldView.vue';
import UnitDetailsPanel from '../canvas/UnitDetailsPanel';

const FIVE_HOURS_MS = 5 * 60 * 60 * 1000;
let _battle_window;
let _engaged_squad_view;
let _gameSubmodeSelector;
let _squad_puppet_mouseover_panel;
let _tickersInterval;
let _unit_details_panel;

const { caverns_data } = CavernsStore.getAll();
const { current_game_submode } = UIStore.getAll();
const state = reactive({
  active_caverns_run: false,
  current_game_submode: current_game_submode,
  pending_claim_call: false,
  pending_claim_delay: false,
  pending_enter_caverns: false,
  portal_level: caverns_data[current_game_submode].portal_level,
  deepest_level: caverns_data[current_game_submode].deepest_level,
  tickers: {
    claim_delay_text: '???',
  },
  is_on_tutorial_step_nav_to_edit_hero: false,
});

let _tutorial_timeout;

onMounted(() => {
  makeGameSubmodeSelector();
  makeEngagedSquadView();

  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, makeUnitDetailsPanel);
  CavernsStore.on(CavernsStore.CAVERNS_PORTAL_LEVEL_SET, onPortalLevelSet);
  CavernsStore.on(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.on(CavernsStore.CAVERNS_TRAVEL_SUCCESS, onCavernsTravelSuccess);
  CavernsStore.on(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
  CavernsStore.on(CavernsStore.PASSIVE_YIELDS_CLAIMED, onPassiveYieldsClaimed);
  GameStateStore.on(GameStateStore.LOADOUT_UPDATED, onLoadoutUpdated);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
  UIStore.on(UIStore.MODAL_CLOSED, onModalClosed);
  TutorialStore.on(
    TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
    onTutorialSpotlightVisible
  );
  TutorialStore.on(
    TutorialStore.TUTORIAL_SPOTLIGHT_HIDDEN,
    onTutorialSpotlightHidden
  );

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);

  Audio.setBGTrack('menu_music');

  _tickersInterval = setInterval(calcTickers, 1000);
  calcTickers();

  // tutorial step 2 - enter caverns
  const { entered_caverns, skipped_all } =
    TutorialStore.getCompletedTutorialSteps();
  if (!entered_caverns && !skipped_all) {
    const enter_at_1_button = document.getElementById('enter_at_1_button');
    const rect = enter_at_1_button.getBoundingClientRect();
    TutorialActions.makeTutorialSpotlight({
      radius: 160,
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height,
    });
  }
});

onBeforeUnmount(() => {
  destroyBattleWindow();
  destroyEngagedSquadView();
  destroyGameSubmodeSelector();
  destroySquadPuppetMouseoverPanel();
  destroyUnitDetailsPanel();
  CavernsStore.removeListener(
    CavernsStore.BATTLE_INITIALIZED,
    makeUnitDetailsPanel
  );
  CavernsStore.removeListener(
    CavernsStore.CAVERNS_PORTAL_LEVEL_SET,
    onPortalLevelSet
  );
  CavernsStore.removeListener(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.removeListener(
    CavernsStore.CAVERNS_TRAVEL_SUCCESS,
    onCavernsTravelSuccess
  );
  CavernsStore.removeListener(
    CavernsStore.RETURNED_TO_SURFACE,
    onReturnedToSurface
  );
  CavernsStore.removeListener(
    CavernsStore.PASSIVE_YIELDS_CLAIMED,
    onPassiveYieldsClaimed
  );
  GameStateStore.removeListener(
    GameStateStore.LOADOUT_UPDATED,
    onLoadoutUpdated
  );
  UIStore.removeListener(
    UIStore.GAME_SUBMODE_SELECTION,
    onGameSubmodeSelection
  );
  UIStore.removeListener(UIStore.MODAL_CLOSED, onModalClosed);
  TutorialStore.removeListener(
    TutorialStore.TUTORIAL_SPOTLIGHT_VISIBLE,
    onTutorialSpotlightVisible
  );
  TutorialStore.removeListener(
    TutorialStore.TUTORIAL_SPOTLIGHT_HIDDEN,
    onTutorialSpotlightHidden
  );

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);

  clearInterval(_tickersInterval);

  clearTimeout(_tutorial_timeout);
  TutorialActions.destroyTutorialBox();
  TutorialActions.destroyTutorialSpotlight();
});

const handleResize = Tools.debounce(() => {
  makeGameSubmodeSelector();
  if (state.active_caverns_run) {
    makeBattleWindow({ is_resize: true });
    if (_unit_details_panel) {
      makeUnitDetailsPanel();
    }
  } else {
    makeEngagedSquadView();
  }
}, 300);

function calcTickers() {
  const submode_data =
    CavernsStore.getAll().caverns_data[UIStore.getAll().current_game_submode];
  if (!submode_data) {
    return;
  }

  // calc & render remaining claim delay
  const now = Date.now();
  const time_left = submode_data.last_passive_yield_claim + FIVE_HOURS_MS - now;
  state.pending_claim_delay = time_left > 0;
  state.tickers.claim_delay_text = Tools.millisecondsToHHMMSS(time_left);
}

function destroyBattleWindow() {
  _battle_window?.dispose();
  _battle_window = null;
}

function destroyEngagedSquadView() {
  _engaged_squad_view?.dispose();
  _engaged_squad_view = null;
}

function destroyGameSubmodeSelector() {
  _gameSubmodeSelector?.dispose();
  _gameSubmodeSelector = null;
}

function destroySquadPuppetMouseoverPanel() {
  _squad_puppet_mouseover_panel?.destroy();
  _squad_puppet_mouseover_panel = null;
}

function destroyUnitDetailsPanel() {
  _unit_details_panel?.dispose();
  _unit_details_panel = null;
}

function makeBattleWindow({ is_resize = false } = {}) {
  _battle_window?.dispose(!is_resize);
  const dom_bounding_rect = CanvasTools.getDomAnchor('caverns_battle_window');
  _battle_window = new CavernsBattleWindow({ dom_bounding_rect, is_resize });
  _battle_window.x = dom_bounding_rect.x;
  _battle_window.y = dom_bounding_rect.y;
  DT_CANVAS_GLOBALS.stage.addChildAt(_battle_window, 1);
}

function makeEngagedSquadView({ do_transition_in = true } = {}) {
  destroyEngagedSquadView();

  const dom_anchor = document.getElementById('caverns_engaged_heroes_view');
  if (!dom_anchor) {
    return;
  }

  const { loadouts } = GameStateStore.getAll().gameState;
  const { current_game_mode, current_game_submode } = UIStore.getAll();

  const num_heroes = getNumHeroesInSubmode(current_game_submode);
  const puppet_scale = num_heroes < 5 ? 1.75 : num_heroes > 5 ? 1.25 : 1.5;

  _engaged_squad_view = new EngagedSquadView({
    hero_builds: HeroBuildStore.getAll().hero_builds,
    loadout_squad_list: loadouts[current_game_mode][
      current_game_submode
    ].filter((hero) => !!hero.engagement),
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale,
    do_transition_in,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      const width_coefficient = 1.18;
      switch (num_heroes) {
        case 1:
          return puppet_scale * 10;
        case 2:
        case 3:
          return (
            width_coefficient * -0.3 + puppet_index * puppet_scale * 100 + 20
          );
        case 5:
        case 7:
        case 11:
          return (
            width_coefficient * -0.25 + puppet_index * puppet_scale * 90 + 0
          );
      }
    },
  });
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();
  _engaged_squad_view.x =
    x +
    width / 2 -
    _engaged_squad_view.width / 2 +
    DT_CANVAS_GLOBALS.spacing * 6.5;
  _engaged_squad_view.y = y + height / 2 - DT_CANVAS_GLOBALS.spacing * 3;
  DT_CANVAS_GLOBALS.stage.addChild(_engaged_squad_view);

  // add squad puppet interaction listeners
  const puppets = _engaged_squad_view.getPuppets();
  for (const puppet of puppets) {
    puppet.mouseover = onSquadPuppetMouseover.bind(null, puppet);
    puppet.mouseout = onSquadPuppetMouseout;
  }
}

function makeGameSubmodeSelector() {
  destroyGameSubmodeSelector();
  _gameSubmodeSelector = new GameSubmodeSelector();
  _gameSubmodeSelector.x =
    window.innerWidth / 2 - _gameSubmodeSelector.width / 2;
  _gameSubmodeSelector.y = 42;
  DT_CANVAS_GLOBALS.stage.addChild(_gameSubmodeSelector);
}

function makeSquadPuppetMouseoverPanel(puppet) {
  const out_build = puppet.actor;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const loadout =
    GameStateStore.getAll().gameState.loadouts[current_game_mode][
      current_game_submode
    ];

  _squad_puppet_mouseover_panel = new SquadPuppetMouseoverPanel({
    onHeroBuildSelected: ({ hero_build_id, hero_handle }) => {
      HeroBuildActions.engageHeroBuild({
        hero_build_id,
        hero_handle,
        game_mode: current_game_mode,
        game_submode: current_game_submode,
        target_slot_index: loadout.find(
          ({ hero_build_id: id }) => id === out_build._id
        ).engagement.slot,
      });
    },
    out_build,
  });
  puppet.addChild(_squad_puppet_mouseover_panel);
}

function makeUnitDetailsPanel() {
  destroyUnitDetailsPanel();

  const dom_anchor = document.getElementById('caverns_left_sidebar');
  if (!dom_anchor) {
    return;
  }

  const { loadouts } = GameStateStore.getAll().gameState;
  const { current_game_mode, current_game_submode } = UIStore.getAll();

  _unit_details_panel = new UnitDetailsPanel({
    loadout_squad_list: loadouts[current_game_mode][
      current_game_submode
    ].filter((hero) => !!hero.engagement),
    puppet_scale: DT_CANVAS_GLOBALS.spacing * 0.2,
    do_transition_in: true,
  });
  const { x, y, height } = dom_anchor.getBoundingClientRect();
  _unit_details_panel.x = Math.round(x + DT_CANVAS_GLOBALS.spacing * 1);
  _unit_details_panel.y = Math.round(
    y + height / 2 - _unit_details_panel.height / 2
  );
  DT_CANVAS_GLOBALS.stage.addChild(_unit_details_panel);
}

function onDescendCavernsAt1Click() {
  state.pending_enter_caverns = true;
  CavernsActions.initNewCavernsMap(1);
}

function onDescendCavernsAtPortalLevelClick() {
  state.pending_enter_caverns = true;
  CavernsActions.initNewCavernsMap(state.portal_level);
}

function onEnteredCaverns(new_caverns_data) {
  state.pending_enter_caverns = false;

  // complete tutorial step 2 - enter caverns
  const { entered_caverns, skipped_all } =
    TutorialStore.getCompletedTutorialSteps();
  if (!entered_caverns && !skipped_all) {
    TutorialActions.destroyTutorialSpotlight();
    TutorialActions.logTutorialStepCompleted('entered_caverns');

    // tutorial step 3 - caverns & basic mechanics explained
    _tutorial_timeout = setTimeout(() => {
      // tutorial step 3a
      TutorialActions.makeTutorialBox({
        text: text('tutorial.caverns_explained_1'),
        x: Math.round(window.innerWidth * 0.14 - 65),
        y: Math.round(window.innerHeight * 0.16),
        width: 180,
        arrow: 'none',
      });
      _tutorial_timeout = setTimeout(() => {
        // tutorial step 3b
        TutorialActions.hideTutorialBox();
        TutorialActions.makeTutorialBox({
          text: text('tutorial.caverns_explained_2'),
          x: Math.round(window.innerWidth * 0.13 - 35),
          y: Math.round(window.innerHeight * 0.45 + 60),
          width: 180,
          arrow: 'up',
        });

        _tutorial_timeout = setTimeout(() => {
          // tutorial step 3c
          TutorialActions.hideTutorialBox();
          TutorialActions.makeTutorialBox({
            text: text('tutorial.caverns_explained_3'),
            x: Math.round(window.innerWidth * 0.6),
            y: Math.round(window.innerHeight * 0.52 - 20),
            width: 180,
            arrow: 'none',
          });
          _tutorial_timeout = setTimeout(() => {
            // tutorial step 3d
            TutorialActions.hideTutorialBox();
            TutorialActions.makeTutorialBox({
              text: text('tutorial.caverns_explained_4'),
              x: Math.round(window.innerWidth * 0.96 - 535),
              y: Math.round(window.innerHeight * 0.99 - 85),
              arrow: 'right',
            });
          }, 12000);
        }, 12000);
      }, 8000);
    }, 3000);
  }

  CavernsActions.startCavernsBattleEngine({ new_caverns_data });
  destroyEngagedSquadView();
  state.active_caverns_run = true;
  _gameSubmodeSelector.muteButtons();
  nextTick(() => {
    // init the caverns gameplay view
    makeBattleWindow();
    makeUnitDetailsPanel();
    Audio.setBGTrack('caverns_theme');
    clearInterval(_tickersInterval);
  });
}

function onCavernsTravelSuccess() {
  state.deepest_level = caverns_data[state.current_game_submode]?.deepest_level;
}

function onGameSubmodeSelection() {
  const { caverns_data } = CavernsStore.getAll();
  state.current_game_submode = UIStore.getAll().current_game_submode;
  state.portal_level = caverns_data[state.current_game_submode]?.portal_level;
  state.deepest_level = caverns_data[state.current_game_submode]?.deepest_level;
  nextTick(() => {
    calcTickers();
    makeEngagedSquadView();
  });
}

function onLoadoutUpdated() {
  makeEngagedSquadView();
}

function onPortalLevelSet({ currentLevel }) {
  state.portal_level = currentLevel;
}

function onReturnedToSurface(completed_caverns_run_data) {
  clearTimeout(_tutorial_timeout);
  TutorialActions.hideTutorialBox();

  _gameSubmodeSelector.unmuteButtons();
  Audio.setBGTrack('menu_music');
  state.active_caverns_run = false;
  state.portal_level =
    CavernsStore.getAll().caverns_data[state.current_game_submode].portal_level;
  nextTick(() => {
    _tickersInterval = setInterval(calcTickers, 1000);
    calcTickers();
    destroyUnitDetailsPanel();
    makeEngagedSquadView();
  });
}

function onSquadPuppetMouseout() {
  destroySquadPuppetMouseoverPanel();
}

function onSquadPuppetMouseover(puppet) {
  makeSquadPuppetMouseoverPanel(puppet);
}

function onModalClosed() {
  // tutorial step 4 - navigate to edit hero
  const { entered_caverns, navigated_to_edit_hero, skipped_all } =
    TutorialStore.getCompletedTutorialSteps();
  if (entered_caverns && !navigated_to_edit_hero && !skipped_all) {
    const spotlight = TutorialActions.makeTutorialSpotlight({
      radius: 160,
      x: _engaged_squad_view.x + 20,
      y: _engaged_squad_view.y + 20,
      onTransitionInComplete: () => {
        spotlight &&
          TutorialActions.makeTutorialBox({
            text: text('tutorial.nav_to_edit_hero'),
            x: Math.round(spotlight.x + 115),
            y: Math.round(spotlight.y - 50),
            arrow: 'left',
          });
      },
    });
  }
}

function onTutorialSpotlightVisible() {
  const { entered_caverns, navigated_to_edit_hero } =
    TutorialStore.getCompletedTutorialSteps();
  if (!entered_caverns && _engaged_squad_view) {
    _engaged_squad_view.interactiveChildren = false;
  }
  if (entered_caverns && !navigated_to_edit_hero) {
    state.is_on_tutorial_step_nav_to_edit_hero = true;
  }
}

function onTutorialSpotlightHidden() {
  if (_engaged_squad_view) {
    _engaged_squad_view.interactiveChildren = true;
  }
  state.is_on_tutorial_step_nav_to_edit_hero = false;
}

function onPassiveYieldsClaimed() {
  // giving time for calcTickers to run
  setTimeout(() => {
    state.pending_claim_call = false;
  }, 1000);
}
</script>

import * as PIXI from 'pixi.js';
import AltarStore from '~/flux/stores/AltarStore';
import AltarImg from './AltarImg';
import PrayerMenu from './PrayerMenu';
import PrayerSummary from './PrayerSummary';
import CandlePillar from './CandlePillar';
import Colors from '~/constants/Colors';
import PrayerResultDisplay from './PrayerResultDisplay';
import ChatActions from '~/flux/actions/ChatActions';
import Audio from '~/Audio';

const Temple = function () {
  PIXI.Container.call(this);

  this.transitionIn = () => {
    TweenMax.from(_levelLabel, 0.4, {
      alpha: 0,
      y: DT_CANVAS_GLOBALS.spacing * 3,
    });
    TweenMax.from(_altarImg, 2.0, { alpha: 0 });
    TweenMax.from(_prayerSummary, 2.5, { alpha: 0 });
    TweenMax.from(_prayerMenu, 0.6, { y: window.innerHeight });
  };

  this.transitionOut = (onComplete) => {
    onComplete();
  };

  this.dispose = () => {
    window.removeEventListener('mousedown', destroyCandlePinwheels);
    AltarStore.removeListener(AltarStore.GOT_ALTAR_STATE, onAltarState);
    AltarStore.removeListener(
      AltarStore.GOT_PRAYER_RESULT_ITEM,
      onPrayerResultItem
    );

    TweenMax.killTweensOf(loop2ndVoice);
    TweenMax.killTweensOf(loop3rdVoice);
    Audio.stop('reverse_tibetan_chant_02');
    Audio.stop('reverse_tibetan_chant_03');

    destroyLevelLabel();
    destroyAltarImg();
    destroyPrayerSummary();
    destroyPrayerMenu();
    destroyPrayerResultDisplay();
    destroyLeftPillar();
    destroyRightPillar();

    this.destroy();
  };

  this.handleResize = () => {
    makeLevelLabel();
    makeAltarImg();
    makeLeftPillar();
    makeRightPillar();
    makePrayerSummary();
    makePrayerMenu();
  };

  const destroyLevelLabel = () => {
    if (_levelLabel) {
      this.removeChild(_levelLabel);
      _levelLabel = null;
    }
  };

  const destroyAltarImg = () => {
    if (_altarImg) {
      _altarImg.dispose();
      this.removeChild(_altarImg);
      _altarImg = null;
    }
  };

  const destroyPrayerSummary = () => {
    if (_prayerSummary) {
      _prayerSummary.dispose();
      this.removeChild(_prayerSummary);
      _prayerSummary = null;
    }
  };

  const destroyPrayerMenu = () => {
    if (_prayerMenu) {
      _prayerMenu.dispose();
      this.removeChild(_prayerMenu);
      _prayerMenu = null;
    }
  };

  const destroyPrayerResultDisplay = () => {
    if (_prayerResultDisplay) {
      _prayerResultDisplay.dispose();
      this.removeChild(_prayerResultDisplay);
    }
  };

  const destroyLeftPillar = () => {
    if (_leftPillar) {
      _leftPillar.dispose();
      this.removeChild(_leftPillar);
    }
  };

  const destroyRightPillar = () => {
    if (_rightPillar) {
      _rightPillar.dispose();
      this.removeChild(_rightPillar);
    }
  };

  const makeLevelLabel = () => {
    destroyLevelLabel();

    _levelLabel = new PIXI.Sprite();
    _levelLabel.texture = PIXI.utils.TextureCache['menu_label.png'];
    _levelLabel.x = Math.round(window.innerWidth / 2 - _levelLabel.width / 2);
    _levelLabel.y = 33 + DT_CANVAS_GLOBALS.spacing * 2.5;
    _levelLabel.tint = Colors.BRIGHT_YELLOW;
    var text = new PIXI.Text('LV ' + _altarState.altarLevel, {
      fontFamily: 'Courier New',
      fontSize: '14px',
      fontStyle: 'bold',
      fill: 0x000000,
    });
    text.x = Math.round(_levelLabel.width / 2 - text.width / 2);
    text.y = Math.round(_levelLabel.height / 2 - text.height / 2);
    _levelLabel.addChild(text);
    this.addChild(_levelLabel);
  };

  const makeAltarImg = () => {
    destroyAltarImg();

    const ai = (_altarImg = new AltarImg(_altarState.alignment));
    ai.height = Math.min(ai.height, window.innerHeight * 0.6);
    ai.scale.x = ai.scale.y;
    ai.x = window.innerWidth / 2 - (ai.ALTAR_WIDTH * ai.scale.x) / 2;
    ai.y = 77;
    this.addChild(ai);
  };

  const makePrayerSummary = () => {
    destroyPrayerSummary();

    const ps = (_prayerSummary = new PrayerSummary(makePrayerMenu));
    ps.width = Math.min(ps.width, window.innerWidth * 0.8);
    ps.scale.y = ps.scale.x;
    ps.x = window.innerWidth / 2;
    ps.y = window.innerHeight * 0.42 - ps.height / 2;
    this.addChild(ps);
  };

  const makePrayerMenu = () => {
    destroyPrayerMenu();

    const pm = (_prayerMenu = new PrayerMenu());
    pm.x = Math.round(window.innerWidth / 2);
    pm.y = Math.round(window.innerHeight * 0.47);
    this.addChild(pm);
  };

  const makeLeftPillar = () => {
    destroyLeftPillar();

    const lp = (_leftPillar = new CandlePillar(
      _altarState.activeCandles[0],
      0
    ));
    lp.width = Math.min(lp.width, window.innerWidth * 0.1);
    lp.scale.y = lp.scale.x;
    lp.height = Math.min(lp.height, window.innerHeight * 0.3);
    lp.scale.x = lp.scale.y;
    lp.x = window.innerWidth * 0.1 - lp.width / 2;
    lp.y = window.innerHeight * 0.63 - (lp.bd.height * lp.scale.y) / 2 - 18;
    this.addChild(lp);
  };

  const makeRightPillar = () => {
    destroyRightPillar();

    const rp = (_rightPillar = new CandlePillar(
      _altarState.activeCandles[1],
      1
    ));
    rp.width = Math.min(rp.width, window.innerWidth * 0.1);
    rp.scale.y = rp.scale.x;
    rp.height = Math.min(rp.height, window.innerHeight * 0.3);
    rp.scale.x = rp.scale.y;
    rp.x = window.innerWidth * 0.9 - rp.width / 2;
    rp.y = _leftPillar.y;
    this.addChild(rp);
  };

  var _altarState = AltarStore.getAll();
  var _levelLabel;
  var _altarImg;
  var _prayerSummary;
  var _prayerMenu;
  var _prayerResultDisplay;
  var _leftPillar;
  var _rightPillar;
  makeLevelLabel();
  makeAltarImg();
  makeLeftPillar();
  makeRightPillar();
  makePrayerSummary();
  makePrayerMenu();

  // reverse tebetan chant audio
  Audio.setBGTrack('reverse_tibetan_chant');
  const loop2ndVoice = () => {
    Audio.play('reverse_tibetan_chant_02');
    TweenMax.delayedCall(23, loop2ndVoice);
  };
  TweenMax.delayedCall(11 + Math.random() * 11, loop2ndVoice);

  const loop3rdVoice = () => {
    Audio.play('reverse_tibetan_chant_03');
    TweenMax.delayedCall(23, loop3rdVoice);
  };
  if (Audio.getMusicVolume() < 0.1) {
    loop3rdVoice();
  }

  const doAltarLevelUp = () => {
    makeLevelLabel();
    TweenMax.from(_levelLabel, 0.4, {
      alpha: 0,
      y: DT_CANVAS_GLOBALS.spacing * 3,
    });
    ChatActions.gameNotification('altar_level_up', {
      level: _altarState.altarLevel,
    });
  };

  const onAltarState = () => {
    var oldLevel = _altarState.altarLevel;

    _altarState = AltarStore.getAll();

    makeLeftPillar();
    makeRightPillar();

    if (_altarState.altarLevel > oldLevel) {
      doAltarLevelUp();
    }
  };
  AltarStore.on(AltarStore.GOT_ALTAR_STATE, onAltarState);

  const onPrayerResultItem = (data) => {
    try {
      if (_prayerResultDisplay) {
        destroyPrayerResultDisplay();
      }

      _prayerResultDisplay = new PrayerResultDisplay(
        data.resultItem,
        data.godTag,
        destroyPrayerResultDisplay
      );
      this.addChild(_prayerResultDisplay);

      Audio.play('electric_smash');
    } catch (err) {
      logError(
        err,
        Object.assign(
          {
            module: 'components/ui_screens/altar/Altar',
            func: 'onPrayerResultItem',
          },
          data.resultItem,
          { godTag: data.godTag }
        )
      );
    }
  };
  AltarStore.on(AltarStore.GOT_PRAYER_RESULT_ITEM, onPrayerResultItem);

  const destroyCandlePinwheels = () => {
    _leftPillar?.destroyCandlePinwheel();
    _rightPillar?.destroyCandlePinwheel();
  };
  window.addEventListener('mousedown', destroyCandlePinwheels);
};
Temple.prototype = Object.create(PIXI.Container.prototype);
Temple.prototype.constructor = Temple;
export default Temple;

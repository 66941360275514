import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';
import { ApplicationStore } from '~/flux/stores';

// set up particle effect rendering
let _effect_pool;
ApplicationStore.on(ApplicationStore.ASSETS_LOADED, () => {
  const _template_effect = new PIXI.Graphics();
  _template_effect.beginFill(0xfeffb5);
  _template_effect.drawRect(-1, -1, 2, 2);
  _template_effect.endFill();

  const _effect_texture =
    window.DT_CANVAS_GLOBALS._pixi_app.renderer.generateTexture(
      _template_effect
    );

  let _effect;
  _effect_pool = new ObjectPool(
    // create func
    () => {
      _effect = new PIXI.Sprite(_effect_texture);
      return _effect;
    },

    // reset func
    (effect) => {
      effect.parent?.removeChild(effect);
      effect.alpha = 0.5 + Math.random() * 0.4;
      effect.scale.y = 1;
    },

    // starting num
    1000
  );
});

const instances = {};

const BlessedRendering = {
  render: function (condition, gameBoard) {
    if (instances[condition.uid]) {
      return;
    }

    const instance = (instances[condition.uid] = {});
    instance.ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
    instance.effect_container = new PIXI.ParticleContainer(100, {
      position: true,
      alpha: true,
    });
    instance.ownerSprite.addChild(instance.effect_container);
    BlessedRendering.makeEffect(condition.uid);
  },

  makeEffect: (conditionId) => {
    if (!instances[conditionId]) {
      return;
    }

    const instance = instances[conditionId];
    const { ownerSprite } = instance;
    if (!ownerSprite) {
      return;
    }

    var effect = _effect_pool.get();
    const { tileImg } = ownerSprite;
    effect.x = -tileImg.width / 2 + Math.random() * tileImg.width;
    effect.y =
      -ownerSprite.height * 0.8 - (Math.random() * ownerSprite.height) / 3;
    instance.effect_container.addChild(effect);

    TweenMax.to(effect, 1.5 + Math.random(), {
      y: 0,
      alpha: 0,
      ease: Linear.easeNone,
      onComplete: _effect_pool.put,
      onCompleteParams: [effect],
    });

    instance?.interval?.kill();
    instance.interval = TweenMax.delayedCall(
      0.1 + Math.random() * 1.2,
      BlessedRendering.makeEffect,
      [conditionId]
    );
  },

  stopRendering: function (conditionId) {
    const instance = instances[conditionId];
    instance?.effect_container?.destroy();
    instance?.interval?.kill();
    delete instances[conditionId];
  },

  dispose: function () {
    for (const conditionId of Object.keys(instances)) {
      BlessedRendering.stopRendering(conditionId);
    }
  },
};
export default BlessedRendering;

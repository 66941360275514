import CavernsStore from '~/flux/stores/CavernsStore';
import FaerieSprite from '~/view/game-screens/battle/canvas/game_board/piece_sprites/FaerieSprite';

const UnitSpawnedRendering = {
  render: function (data, gameBoard, isCaverns) {
    const spawneeSprite = gameBoard.getAllPieceSprites()[data.unit.uid];
    const handle = data.unit.handle;

    if (isCaverns) {
      const actor = CavernsStore.getAll().battleState.allUnits[data.unit.uid];
      const tileSprites = gameBoard.getTileSprites();
      for (var prop in tileSprites) {
        var tSprite = tileSprites[prop];
        var tile = tSprite.gamePiece;
        if (tile.x === actor.x && tile.y === actor.y) {
          tSprite.tileImg.visible = false;
          if (tSprite.lootImg) {
            tSprite.lootImg.visible = false;
          }
          break;
        }
      }
    }

    if (handle === 'dwarf' || handle === 'elf' || handle === 'faerie') {
      if (spawneeSprite?.puppet) {
        TweenMax.from(spawneeSprite.puppet.scale, 0.3, { y: 0 });
        // addressing a bug where the above tween doesn't finish:
        setTimeout(() => {
          spawneeSprite.puppet.scale.y = 1;
        }, 3200);
        TweenMax.from(spawneeSprite.tileImg, 0.8, {
          alpha: 0,
          ease: Expo.easeIn,
        });
        TweenMax.from(spawneeSprite.statBar, 1.1, {
          alpha: 0,
          ease: Expo.easeIn,
        });
      }
    }

    if (handle === 'faerie') {
      if (spawneeSprite) {
        spawneeSprite.faerieSprite = new FaerieSprite();
        spawneeSprite.addChildAt(spawneeSprite.faerieSprite, 1);
      }
    }
  },
};
export default UnitSpawnedRendering;

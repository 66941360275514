import ArrowEffect from '../effects/ArrowEffect';
import CanvasTools from '~/view/CanvasTools';
import Audio from '~/Audio';

const FlyingArrow = function (
  actorSprite,
  victimSprite,
  projectileTime,
  playSound = true
) {
  if (!actorSprite || !actorSprite.parent) {
    return;
  }

  var arrow = new ArrowEffect();
  arrow.pivot.x = arrow.pivot.y = 0.5;
  arrow.scale.x = victimSprite.scale.x * 0.05;
  arrow.scale.y = victimSprite.scale.y * 0.05;
  arrow.rotation = CanvasTools.angleFromSpriteToSprite(
    actorSprite,
    victimSprite
  );

  arrow.x = actorSprite.x;
  arrow.y =
    actorSprite.y -
    (actorSprite.puppet
      ? actorSprite.height * actorSprite.scale.y * 0.36
      : actorSprite.tileImg.height / 3);
  actorSprite.parent.addChild(arrow);

  TweenMax.to(arrow, projectileTime / 1000, {
    x: victimSprite.x,
    y: victimSprite.y - victimSprite.tileImg.height / 3,
    ease: Linear.easeNone,
    onComplete: (a) => a?.parent?.removeChild(a),
    onCompleteParams: [arrow],
  });

  playSound && Audio.play('fire_bow');

  return arrow;
};
export default FlyingArrow;
